import { Ability } from '@casl/ability'
// import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
// import _ from 'lodash'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = sessionStorage.getItem('userData') ? JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8)) : null
const existingAbility = userData

export default new Ability(existingAbility || initialAbility)
