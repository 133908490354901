// import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import jwt from '@/auth/jwt/jwt'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return sessionStorage.getItem('userData') && sessionStorage.getItem(jwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  console.log(userRole)
  return '/'
}
