import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueAuthenticate from 'vue-authenticate'
// import Select2 from 'v-select2-component'
import excel from 'vue-excel-export'
import VueExcelXlsx from 'vue-excel-xlsx'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

const moment = require('moment')

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Authentication
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: 'code',
  providers: {
    mygms: {
      url: '/mygms',
      tokenPath: 'code',
      clientId: process.env.VUE_APP_MYGMS_OAUTH_CLIENT_ID,
      redirectUri: process.env.VUE_APP_MYGMS_OAUTH_CALLBACK_URL,
      clientSecret: process.env.VUE_APP_MYGMS_OAUTH_CLIENT_SECRET,
      responseType: 'code',
      oauthType: '2.0',
      authorizationEndpoint: `${process.env.VUE_APP_MYGMS_OAUTH_DOMAIN}/oauth/authorize`,
      state: '1',
      optionalUrlParams: ['state'],
    },
  },
})

// Moment
Vue.use(require('vue-moment'), {
  moment,
})

// Export
Vue.use(excel)
Vue.use(VueExcelXlsx)

// Select 2
// Vue.component('Select2', Select2)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

export default app
