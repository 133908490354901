import { isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'

export default [
  {
    path: '/servolution/ministries',
    name: 'servolution-ministries',
    component: () => import('@/views/servolution/ministries/Lists.vue'),
    meta: {
        pageTitle: 'Ministries',
        resource: 'servolution_ministries',
        action: 'read',
        breadcrumb: [
            {
                text: 'Ministries',
            },
            {
                text: 'Lists',
                active: true,
            },
        ],
        createUrl: 'create-servolution-ministry',
        showCreateButton: true,
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/ministry/create',
    name: 'create-servolution-ministry',
    component: () => import('@/views/servolution/ministries/Create.vue'),
    meta: {
        pageTitle: 'Add Ministry',
        resource: 'servolution_ministries',
        action: 'create',
        requiresAuth: true,
        breadcrumb: [
            {
                text: 'Ministries',
            },
            {
                text: 'Create',
                active: true,
            },
        ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/ministry/edit/:id',
    name: 'edit-servolution-ministry',
    component: () => import('@/views/servolution/ministries/Edit.vue'),
    meta: {
        pageTitle: 'Edit Ministry',
        resource: 'servolution_ministries',
        action: 'edit',
        requiresAuth: true,
        breadcrumb: [
            {
                text: 'Ministries',
            },
            {
                text: 'Edit',
                active: true,
            },
        ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/divisions',
    name: 'servolution-divisions',
    component: () => import('@/views/servolution/divisions/Lists.vue'),
    meta: {
        pageTitle: 'Divisions',
        resource: 'servolution_divisions',
        action: 'read',
        breadcrumb: [
            {
                text: 'Divisions',
            },
            {
                text: 'Lists',
                active: true,
            },
        ],
        createUrl: 'create-servolution-division',
        showCreateButton: true,
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/division/create',
    name: 'create-servolution-division',
    component: () => import('@/views/servolution/divisions/Create.vue'),
    meta: {
        pageTitle: 'Add Division',
        resource: 'servolution_divisions',
        action: 'create',
        requiresAuth: true,
        breadcrumb: [
            {
                text: 'Divisions',
            },
            {
                text: 'Create',
                active: true,
            },
        ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/division/edit/:id',
    name: 'edit-servolution-division',
    component: () => import('@/views/servolution/divisions/Edit.vue'),
    meta: {
        pageTitle: 'Edit Division',
        resource: 'servolution_divisions',
        action: 'edit',
        requiresAuth: true,
        breadcrumb: [
            {
                text: 'Divisions',
            },
            {
                text: 'Edit',
                active: true,
            },
        ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
      path: '/servolution/events',
      name: 'servolution-events',
      component: () => import('@/views/servolution/events/Lists.vue'),
      meta: {
          pageTitle: 'Events',
          resource: 'servolution_events',
          action: 'read',
          breadcrumb: [
              {
                  text: 'Events',
              },
              {
                  text: 'Lists',
                  active: true,
              },
          ],
          createUrl: 'create-servolution-event',
          showCreateButton: true,
      },
      beforeEnter: (to, from, next) => {
          // Get logged in status
          const isLoggedIn = isUserLoggedIn()
    
          // If not logged in, then redirect to login page
          if (!isLoggedIn) {
            return next('/login')
          }
          
          // If login and not have access
          if (!canNavigate(to)) {
            return next({ name: 'misc-not-authorized' })
          }
    
          return next()
      },
  },
  {
    path: '/servolution/event/create',
    name: 'create-servolution-event',
    component: () => import('@/views/servolution/events/Create.vue'),
    meta: {
        pageTitle: 'Add Event',
        resource: 'servolution_events',
        action: 'create',
        requiresAuth: true,
        breadcrumb: [
            {
                text: 'Events',
            },
            {
                text: 'Create',
                active: true,
            },
        ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/event/edit/:id',
    name: 'edit-servolution-event',
    component: () => import('@/views/servolution/events/Edit.vue'),
    meta: {
        pageTitle: 'Edit Event',
        resource: 'servolution_events',
        action: 'edit',
        requiresAuth: true,
        breadcrumb: [
            {
                text: 'Events',
            },
            {
                text: 'Edit',
                active: true,
            },
        ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/ministry/setup/:id',
    name: 'servolution-ministry-setup',
    component: () => import('@/views/servolution/events/ministry_setup/Lists.vue'),
    meta: {
      pageTitle: 'Events',
      resource: 'servolution_ministry_setup',
      action: 'read',
      breadcrumb: [
          {
            text: 'Events',
          },
          {
            text: 'Ministry Setup',
          },
          {
            text: 'Lists',
            active: true,
          },
      ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/ministry/setup/edit/:eventId/:divisionId',
    name: 'edit-servolution-ministry-setup',
    component: () => import('@/views/servolution/events/ministry_setup/Edit.vue'),
    meta: {
      pageTitle: 'Events',
      resource: 'servolution_ministry_setup',
      action: 'edit',
      breadcrumb: [
          {
            text: 'Events',
          },
          {
            text: 'Ministry Setup',
          },
          {
            text: 'Edit',
            active: true,
          },
      ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/participants/:id',
    name: 'servolution-participants',
    component: () => import('@/views/servolution/events/participants/Lists.vue'),
    meta: {
      pageTitle: 'Events',
      resource: 'servolution_participants',
      action: 'read',
      breadcrumb: [
          {
            text: 'Events',
          },
          {
            text: 'Participants',
          },
          {
            text: 'Lists',
            active: true,
          },
      ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/participants/edit/:id',
    name: 'edit-servolution-participant',
    component: () => import('@/views/servolution/events/participants/Edit.vue'),
    meta: {
      pageTitle: 'Events',
      resource: 'servolution_participants',
      action: 'edit',
      breadcrumb: [
          {
            text: 'Events',
          },
          {
            text: 'Participants',
          },
          {
            text: 'Edit',
            active: true,
          },
      ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/assessment/:id',
    name: 'servolution-assessment',
    component: () => import('@/views/servolution/events/assessment/View.vue'),
    meta: {
      pageTitle: 'Events',
      resource: 'servolution_assessment',
      action: 'view',
      breadcrumb: [
          {
            text: 'Events',
          },
          {
            text: 'Assessment',
            active: true,
          },
      ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/baptism',
    name: 'servolution-baptism',
    component: () => import('@/views/servolution/baptism/Lists.vue'),
    meta: {
        pageTitle: 'Baptism',
        resource: 'servolution_baptism',
        action: 'read',
        breadcrumb: [
            {
                text: 'Baptism',
            },
            {
                text: 'Lists',
                active: true,
            },
        ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
  {
    path: '/servolution/statistics/:id',
    name: 'servolution-statistics',
    component: () => import('@/views/servolution/events/statistics/View.vue'),
    meta: {
        pageTitle: 'Statistics',
        resource: 'servolution_statistics',
        action: 'read',
        breadcrumb: [
            {
                text: 'Servolution',
            },
            {
                text: 'Statistics',
                active: true,
            },
        ],
    },
    beforeEnter: (to, from, next) => {
        // Get logged in status
        const isLoggedIn = isUserLoggedIn()
  
        // If not logged in, then redirect to login page
        if (!isLoggedIn) {
          return next('/login')
        }
        
        // If login and not have access
        if (!canNavigate(to)) {
          return next({ name: 'misc-not-authorized' })
        }
  
        return next()
    },
  },
]